.aboutkbp {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .container-aboutkbp {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: -60px;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        margin-top: 80px;
      }
      .heading-aboutkbp {
        width: 100%;
        margin-top: -5%;
        @media (max-width: 768px) {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .logokbp {
            width: 100%; 
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            margin-bottom: 25px;
            @media (max-width: 768px){
              width: 90%;
            }
        }
        .gambarkbp{
            width: 50%;
            justify-content: center;
            align-content: center;
            align-items: center;
            @media (max-width: 768px) {
              width: 90%;
              margin-left: 2%;
              margin-top: -20%;
              margin-bottom: -10%;
            }
        }
        .judulkbp {
            width: 100%;
            font-family: "Poppins", sans-serif;
            font-size: 75px;
            font-weight: 500;
            line-height: 1.1em;
            color: rgb(45, 117, 180) ;
            margin-bottom: 20px;
            @media (max-width: 768px) {
              width: 100%;
              font-size: 30px;
              text-align: center;

            }
          }
            .judulkbp {
                width: 80%;
                align-content: center;
                justify-content: center;
                align-items: center;
                margin-left: 125px;
                font-family: "Poppins", sans-serif;
                font-size: 40px;
                font-weight: 400;
                line-height: 1.1em;
                color: rgb(132, 132, 132);
                margin-top: -40px;
                margin-bottom: 20px;
                @media (max-width: 768px) {
                  width: 100%;
                  font-size: 30px;
                  text-align: center;
                  margin-right: 35%;
                  margin-top: 10px;
            }
          }
        }
        .desk {
          font-size: "Poppins", sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.5em;
          color: rgb(44, 44, 44);
          text-align: center;
          @media (max-width: 768px) {
            width: 80%;
            text-align: center;
          }
        }
        .button {
          display: flex;
          margin-top: 20px;
          justify-content: center;
          align-content: center;
          margin-top: 20px;
          @media (max-width: 768px) {
            width: 100%;
            justify-content: center;
          }
          .wa {
            font-size: 16px;
            justify-content: center;
            padding: 15px 100px;
            position: absolute;
            z-index: 1;
            cursor: pointer;
            background-color: rgb(45, 117, 180);
            border-width: 1px;
            border-color: rgb(45, 117, 180);
            border-radius: 25px;
            border-style: solid;
            color: white;
            &:hover {
              margin-top: 3px;
              margin-left: 5px;
              padding: 15px 100px;
              border-radius: 25px;
              background-color: #ffffff;
              border-width: 1px;
              border-color: rgb(45, 117, 180);
              border-style: solid;
              color: rgb(45, 117, 180);
              transition: transform 0.3s ease-in-out;
            }
            @media (max-width: 768px) {
              width: 80%;
              padding: 10px 50px;
            }
          }
        }
      }
      .gambaraboutkbp {
        display: flex;
        // justify-content: center;
        position: relative;
        align-items: center;
        width: 100%;
        background-image: url("../../../media//kbp/Nilapadmi//Nilapadmi\ \(3\).webp");
        background-size: 75%;
        background-repeat: no-repeat;
        background-position: center;
        height: 120vh;
        @media (max-width: 768px) {
          width: 98%;
          flex-direction: column;
          justify-content: end;
          height: 70vh;
          margin-top: -5%;
          margin-bottom: -50%;
        }
        &:active {
          transform: scale(1.1); /* Adjust scaling factor */
        }
        .fitur-about {
          margin: 74px 0px 0px -14px;
          padding: 25px 35px 35px 35px;
          border-style: solid;
          border-color: #c4cbdb;
          border-width: 1px;
          border-radius: 15px;
          box-shadow: -10px 32px 54px 0px rgba(149, 155, 166, 0.30196078431372547);
          display: flex;
          justify-content: space-between;
          row-gap: 20px;
          flex-direction: column;
          width: 30%;
          background-color: #ffffff;
          &:hover {
            box-shadow: 0px 0px 10px 0px rgba(149, 155, 166, 0.30196078431372547);
            transition: 0.3s;
          }
          @media (max-width: 768px) {
            width: 60%;
            margin: 100px 0px -95px 0px;
            flex-direction: column;
          }
          .logo-navapark {
            width: 100%;
            .logo {
              width: 100%;
              object-fit: cover;
            }
          }
          .judul1 {
            font-family: "Poppins", sans-serif;
            color: #0d3276;
            font-weight: 500;
          }
          .desk1 {
            font-family: "Poppins", sans-serif;
            color: #5a5a5a;
            font-weight: 300;
            font-size: 12px;
          }
          .desk2 {
            margin-top: 15px;
            font-family: "Poppins", sans-serif;
            color: #808080;
            font-weight: 300;
            font-size: 15px;
          }
          .buttoncta {
            .cta {
              font-size: 16px;
              padding: 0px;
              justify-content: center;
              z-index: 1;
              cursor: pointer;
              color: #0d3276;
              background-color: #0d327600;
              border: none;
              text-decoration: underline;
            }
          }
        }
      }
    }