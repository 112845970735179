.kartu{
    width: 100%;
    height: auto;
    .banner{
        width: 100%;
        margin: auto;
        height: 100%;
        
    }
    .slick-next::before,
    .slick-prev::before {
      font-family: "slick";
      font-size: 30px;
    }

    .slick-next {
      right: 100px;
      @media (max-width: 768px){
        right: 50px;
      }
    }
    .slick-prev {
      z-index: 1;
      left: 90px;
      @media (max-width: 768px){
        left: 50px;
      }
    }
    .slick-dots {
      position: absolute; /* Make dots absolute for positioning within slides */
    bottom: 10px; /* Adjust vertical positioning */
    left: 50%; /* Center dots horizontally */
    transform: translateX(-50%); /* Center dots precisely */
    z-index: 1; /* Ensure dots are above image content */
      li {
        margin: 0;
      }
    }
}