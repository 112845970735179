@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.containercard2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding-bottom: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
      overflow: hidden;
      margin-bottom: -20px;
      margin-top: 40px;
    }
    .card {
      width: 30%;
      margin-left: 15px;
      margin-top: 10px;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 0 10px 0;
      padding-bottom: 8px;
      hr {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        width: 90%;
      }
      @media (max-width: 768px) {
        width: 90%;
        margin-bottom: 20px;
        margin-left: 0px;
        height: auto;
      }
      .imagecard {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        height:auto;
      }
      .imagecard1 {
        width: 100%;
      }
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .slick-list {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

      .slick-dots {
        position: absolute; /* Make dots absolute for positioning within slides */
        bottom: 10px; /* Adjust vertical positioning */
        left: 50%; /* Center dots horizontally */
        transform: translateX(-50%); /* Center dots precisely */
        z-index: 1; /* Ensure dots are above image content */
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }

      .container-judul-kartu {
        width: 100%;
        margin-top: 10px;
        display: flex;

        .judulkartu {
          width: 50%;
          padding: 10px;
          margin: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .nama-rumah {
            color: rgb(45, 117, 180);
            font-family: "Poppins";
            font-size: 25px;
            font-weight: 600;
            text-align: center;
          }
          span {
            color: rgb(132, 132, 132);
          }
        }
        .cicilan {
          width: 50%;
          background-color: rgb(45, 117, 180);
          padding: 10px;
          border-radius: 10px 0px 0 10px;
          color: white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .angka {
            font-family: "Poppins";
            font-weight: 300;
            font-size: 48px;
          }
          .ket {
            font-family: "Poppins";
            font-size: 17px;
          }
        }
      }
      .gridspek {
        padding-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: #000000;
            display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 5px;
          padding-right: 10px;
          @media (max-width: 768px) {
            padding-right: 20px;
            font-size: 12px;
          }
        }
      }
      .containerwhatsapp {
        display: flex;
        justify-content: center;
        padding-top: 25px;
        .whatsapp {
          display: inline-block;
          padding: 15px 100px;
          cursor: pointer;
          border: none;
          border-radius: 25px;
          background-color: rgb(45, 117, 180); /* WhatsApp green */
          color: white;
          font-size: 16px;
          &:hover {
            margin-top: 3px;
            margin-left: 5px;
            padding: 15px 100px;
            border-radius: 25px;
            background-color: #ffffff;
            border-width: 1px;
            border-color: rgb(45, 117, 180);
            border-style: solid;
            color: rgb(45, 117, 180);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }
