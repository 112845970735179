.rumah1{
    font-weight: 200;
    background-color:rgb(60, 140, 209) ;
    font-style: normal;
    font-family: sans-serif;
    margin-top: -125px;
    padding-top: 5px;
    margin-bottom: 25px;
    padding-bottom: 50px;
    @media (max-width: 768px){
        margin-top: 135px;
    }
    .container-header1{
        justify-content: center;
        margin-top: 4%;
        padding-bottom: 10%;
        font-size: 60px;
        font-family: 'Poppins' sans-serif;
        font-weight: 400;
        text-align: center;
        color: #C88C39;
        margin-bottom: 20px;
        @media (max-width: 768px){
            margin-top: -15%;
            font-size: 36px;
        }
    }
    .Judulnewlaunch{
        display: flex ;
        justify-content: center;
        font-family: 'poppins' ;
          font-size: 50px;
          font-weight: 500;
          color: rgb(255, 253, 253);
          margin-top: 50px;
          margin-bottom: 50px;
          @media (max-width: 768px){
            text-align: center;
            font-size: 26px;
            font-weight: 500;
            font-family: 'poppins' sans-serif;
            margin-bottom: 75px;
        }
    }
    .rumah-card{
        @media (max-width: 768px){
            margin-top: -15%;}
    }
}
