.rumah{
    margin-bottom: -30px;
    font-weight: 200;
    font-style: normal;
    font-family: sans-serif;
    @media (max-width: 768px){
        //margin-top: 135px;
    }
    .container-header1{
        justify-content: center;
        margin-top: 4%;
        padding-bottom: 10%;
        font-size: 60px;
        font-family: 'Poppins' sans-serif;
        font-weight: 400;
        text-align: center;
        color: #C88C39;
        margin-bottom: 20px;
        @media (max-width: 768px){
            margin-top: -15%;
            font-size: 36px;
        }
    }
    .Judul{
        display: flex ;
        justify-content: center;
        font-family: 'poppins' sans-serif;
          font-size: 40px;
          font-weight: 400;
          color: rgb(132, 132, 132);
          margin-top: 50px;
          margin-bottom: 50px;
          @media (max-width: 768px){
            text-align: center;
            font-size: 28px;
            margin-bottom: 25%;
        }
    }
    .rumah-card{
        @media (max-width: 768px){
            margin-top: -15%;}
    }
}
