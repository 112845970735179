.home {
  @media (max-width: 768px) {
    overflow-x: clip;
    width: 100%;
  }
  .tombolwa {
    position: sticky;
    bottom: 5%;
    cursor: pointer;
    width: 90px;
    left: 93%;
    @media (max-width: 768px) {
      opacity: 0;
    }
  }
  .wamobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: white;
    box-shadow: 0 0 10px 0;
    opacity: 0;
    @media (max-width: 768px) {
      margin-top: 50px;
      padding-top: 15px;
      padding-bottom: 15px;
      position: sticky;
      bottom: 0%;
      opacity: 1;
    }
    .mobile {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding: 8px 40px;
      border: none;
      border-radius: 6px;
      background-color: rgb(60, 140, 209); /* WhatsApp green */
      color: white;
      font-size: 18px;
      box-shadow: 5px 0px 20px -4px rgba(0, 0, 0, 0.55);
      div {
        width: 200px;
      }
    }
  }
}

