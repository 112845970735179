.container-headerkbp {
    display: flex;
    width: 100%;
    box-shadow: 1px 1px 5px 1px rgb(93, 93, 93);
    .logokbp{
        width: 100%;
        padding: 10px;
        @media (max-width: 768px) {
        width: 100%;
        margin-left: 50px;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 20px;
        }
        .gambarkbp{
            
            width: 18%;
            margin-left: 10%;
        @media (max-width: 768px) {
            margin-top: -20px;
            margin-bottom: -20px;
            margin-left:18% ;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            }    
        }
    }
    .container-buttonwa {
        @media (max-width: 768px) {
            visibility: hidden;
            width: 0%;
        }
        width: 100%;
        align-items: center;
        justify-content: right;
        display: flex;
        .button{
            margin-right: 10%;
            display: inline-block;
          padding: 15px 30px;
          color: white;
          margin-top: 30px;
          background-color: rgb(45, 117, 180);
          border: 1px solid rgb(45, 117, 180); /* Add this line for black border */
          border-color: rgb(45, 117, 180);
          border-radius: 25px;
          font-size: 16px;
          font-weight: light;
          cursor: pointer;
          display: inline-block;
          transition: background-color 0.3s ease, color 0.3s ease;
          @media (max-width: 768px) {
            width: 70%;
          }
          &:hover {
            background-color: white;
            color: rgb(45, 117, 180);
        }
    }
    }
}