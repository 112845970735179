@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.surroundingarea {
    .container-surrounding {
        margin-top: 100px;
        hr {
            margin-top: 5%;
            width: 75%;
            //margin-bottom: 2%;
        }
        .judul-surrounding {
            display: flex;
            align-content: center;
            justify-content: center;

            //margin-bottom: 2%;
            h1 {
                font-family: 'poppins' sans-serif;
                font-size: 50px;
                font-weight: 400;
                color: rgb(132, 132, 132);

                @media (max-width: 768px) {
                    text-align: center;
                    font-size: 32px;
                    margin-top: -15%;
                }
            }
        }

        .container-card {
            .kartu {
                .kartugambar1 {
                    width: 75%;
                    margin: auto;
                    border-radius: 15px;

                    @media (max-width: 768px) {
                        width: 95%;
                        height: 75%;
                    }


                }

                .slick-next::before,
                .slick-prev::before {
                    font-family: "slick";
                    font-size: 30px;
                }

                .slick-next {
                    right: 300px;

                    @media (max-width: 768px) {
                        right: 50px;
                    }
                }

                .slick-prev {
                    z-index: 1;
                    left: 280px;

                    @media (max-width: 768px) {
                        left: 50px;
                    }
                }

                .slick-dots {
                    position: absolute;
                    /* Make dots absolute for positioning within slides */
                    bottom: 10px;
                    /* Adjust vertical positioning */
                    left: 50%;
                    /* Center dots horizontally */
                    transform: translateX(-50%);
                    /* Center dots precisely */
                    z-index: 1;

                    /* Ensure dots are above image content */
                    li {
                        margin: 0;
                    }
                }
            }
        }
    }
        .Lokasi12 {
            width: 100%;
            margin-bottom: 100px;
            margin-top: -25px;
            .benefit-container12 {
               width: 100%;
                display: flex;
                flex-direction: row;
                margin-top: 25px;
                justify-content: center;
                align-items: center;
                align-content: center;
    
                @media (max-width: 768px) {
                    flex-direction: column;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: -35%;
                }
    
                .benefit-point1 {
                    color: #535353;
                    padding-right: 45px;
    
                    @media (max-width: 768px) {
                        flex-direction: column;
                        padding-right: 0;
                        padding-left: 25px;
                        width: 80%;
    
                    }
    
                    .benefit-title1 {
                        font-family: "Robbotto", Sans-serif;
                        font-size: 40px;
                        font-weight: 600;
                        font-size: 30px;
                        padding: 10px 25px 25px 0;
                    }
    
                    .benefit1 {
                        font-size: 18px;
                    }
                }
            }
        
    }
    

}